<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2 row"
      >
        <b-form-group
          :label="$t('Именование')"
          label-for="name"
          class="col-12"
        >
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              :state="errors.length > 3 ? false:null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Адрес')"
          label-for="address"
          class="col-12"
        >
          <validation-provider
            #default="{ errors }"
            name="address"
            rules="required"
          >
            <b-form-input
              id="address"
              v-model="form.address"
              :state="errors.length > 3 ? false:null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Директор ФИО')"
          class="col-12"
          label-for="director_fio"
        >
          <validation-provider
            #default="{ errors }"
            name="director_fio"
            rules="required"
          >
            <b-form-input
              id="director_fio"
              v-model="form.director_fio"
              :state="errors.length > 3 ? false:null"
              name="director_fio"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Телефон')"
          label-for="phone"
          class="col-6"
        >
          <validation-provider
            #default="{ errors }"
            name="phone"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend
                is-text
              >
                +998
              </b-input-group-prepend>

              <b-form-input
                id="v-phone"
                v-model="form.phone"
                v-mask="'#########'"
                name="v-phone"
                :state="errors.length > 3 ? false:null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('ИНН')"
          class="col-6"
          label-for="tin"
        >
          <validation-provider
            #default="{ errors }"
            name="tin"
            rules="required"
          >
            <b-form-input
              id="tin"
              v-model="form.tin"
              v-mask="'#########'"
              type="number"
              :state="errors.length > 3 ? false:null"
              name="tin"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Рассчетный счет')"
          label-for="account_number"
          class="col-6"
        >
          <validation-provider
            #default="{ errors }"
            name="account_number"
            rules="required"
          >
            <b-form-input
              id="account_number"
              v-model="form.account_number"
              v-mask="'####################'"
              :state="errors.length > 3 ? false:null"
              name="account_number"
              type="number"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('Банк')"
          label-for="bank_name"
          class="col-6"
        >
          <validation-provider
            #default="{ errors }"
            name="bank_name"
            rules="required"
          >
            <b-form-input
              id="bank_name"
              v-model="form.bank_name"
              :state="errors.length > 3 ? false:null"
              name="bank_name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          :label="$t('МФО Банка')"
          label-for="mfo"
          class="col-6"
        >
          <validation-provider
            #default="{ errors }"
            name="mfo"
            rules="required"
          >
            <b-form-input
              id="mfo"
              v-model="form.mfo"
              v-mask="'#####'"
              type="number"
              :state="errors.length > 3 ? false:null"
              name="mfo"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver,
  },
  props: {
  },
  data() {
    return {
      form: {
        id: null,
        name: '',
        tin: null,
        address: null,
        phone: null,
        account_number: null,
        bank_name: '',
        mfo: null,
        director_fio: null,
      },
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ cities: 'city/GET_ITEMS' }),
    itemId() {
      return this.$route.query.id
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.title = this.$t("Mijoz qo'shish")

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.form.tin = item.tin
      this.form.address = item.address
      this.form.phone = item.phone
      this.form.bank_name = item.bank_name
      this.form.mfo = item.mfo
      this.form.director_fio = item.director_fio
      this.form.account_number = item.account_number
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'buyers/store', updateItem: 'buyers/update',
    }),
  },
}
</script>

<style scoped>

</style>
