<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        :has-show="true"
        model="provider"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      filterModel: {
        per_page: 15,
        number: null,
        address: null,
        region_id: null,
        city_id: null,
        calculating_type_id: null,
      },
      columns: [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Наименование'),
          field: 'name',
          showField: true,
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Адрес'),
          sortable: false,
          field: 'address',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Телефон'),
          sortable: false,
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('ИНН'),
          field: 'tin',
          sortable: false,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Действия'),
          sortable: false,
          field: 'action',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'buyers/GET_ITEMS',
    }),
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'buyers/index',
      destroyAction: 'buyers/destroy',
    }),
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page })
      this.loading = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') { return nameuz }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
    mapCols(filterName, res) {
      this.columns.map(item => {
        if (item.field === `${filterName}.name_uz` || item.field === `${filterName}.name_ru`) {
          if (res) {
            item.filterOptions.filterDropdownItems = res.data.data.map(i => {
              i.value = i.id
              i.text = this.nameByLocal(i.name_uz, i.name_ru)
              return i
            })
          } else {
            item.filterOptions.filterDropdownItems = [
              { value: null, text: '' },
            ]
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
